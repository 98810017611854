* {
    box-sizing: border-box;
}

html,
body {
    max-width: 100%;
    font-family: 'tajawal', sans-serif;
    margin: 0;
    padding: 0;
}

p {
    margin-bottom: 1rem;
}

img {
    max-width: 100%;
    height: auto;
}


//THIS IS A VARIABLES 
@bg-color: #f1f1f1;
@color: #182153;
@line-height: 1.5em;
@font-size: 27px;
@font-weight: 600;
@letter-spacing: 3px;
@mobile-pading: 20px;
.bg-grey {
    background: @bg-color;
}

.navbar-brands {
    font-size: 33px;
    color: #182153;
    &:hover {
        color: #AEB4D4;
        transition: color 0.4s ease 0s;
        text-decoration: none;
    }
}

.nav-links {
    color: @color;
    padding: 0 25px;
    &:hover {
        color: #AEB4D4;
        transition: color 0.4s ease 0s;
        text-decoration: none;
    }
}

.navbar {
    background: linear-gradient(#f1f4f7, #fff, #f1f4f7);
    padding: 25px 40px;
    @media screen and (max-width: 991px) {
        background: white !important;
        .nav-item {
            padding: 10px;
            text-align: center;
        }
    }
}

.navbar-toggler {
    border-radius: 100%;
    padding: 0.25rem 0.25rem;
}

.navbar-nav {
    margin-left: auto;
}

.parallax-window {
    position: relative;
}

.parallax-bg {
    background: rgba(24, 33, 83, 0.7);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.visit-card {
    p {
        margin: 0;
    }
    max-width: 940px;
    position: relative;
    z-index: 3;
    margin: 0 auto;
    top: 75px;
    padding-top: 35px;
}

.visit-card-info {
    display: flex;
    flex-direction: row;
    .info-flex {
        width: 50%;
    }
    .profile-picture {
        filter: grayscale(100%);
        background-image: url('/barborikova/images/portrait.jpg');
        background-size: cover;
        max-width: 100%;
        opacity: 0;
        
        transform: translateX(-10%);
        transition: all 2s ease-in-out;

        &.active {
            opacity: 1;
            transform: translateX(0);
        }
    }
    .text-info-custom {

        background: #fff;
        padding: 90px 80px;
        opacity: 0;
        
        transform: translateX(10%);
        transition: all 2s ease-in-out;

        &.active {
            opacity: 1;
            transform: translateX(0);
        }
    }

    .info-wrapper {

        color: @color;
        .h2, .h6 {
            margin: 0;
        }

        .h2 {
            font-size: 30px;
            letter-spacing: 0.01rem;
            color: @color;
        }

        .h6 {
            padding-top: 5px;
            font-weight: 600;
            letter-spacing: 0.08rem;
            color: @color;
            margin-bottom: 30px;
        }

        overflow: hidden;

    }

}

.card-logo-list {
    list-style: none;
    text-align: center;
    background: @color;
    padding: 25px;
    margin-bottom: 0;
    opacity: 0;
    
    transform: translateY(-50px);
    transition: all 2s ease-in-out;

    &.active {
        opacity: 1;
        transform: translateY(0);
    }

    li {
        display: inline;
        padding: 0 10px;
    }
}

.skills-wrapper {
    position: relative;
    z-index: 1;
    max-width: 945px;
    margin: 0 auto;
    padding: 40px @mobile-pading;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .skill {
        
        width: 45%;
        color: #fff;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: center;
        padding: 25px 20px;
        background-color: rgba(255, 255, 255, 0.15);
        margin-top: 10px;
        margin-bottom: 10px;
        transform: scaleX(0);
        transform-origin: left center;
        opacity: 0;
        &.a-1{
            transition: all 1s ease-in-out;
        }
        &.a-2{
            transition: all 1.5s ease-in-out;
        }

        &.a-3 {
            transition: all 2s ease-in-out;
        }
        @media screen and (max-width: 600px) {
            width: 100%;
        }
        &.active {
            transform: scaleX(1);
            opacity: 1;
        }
    }
}

.bio {
    background: #f1f1f1;
    text-align: center;
    padding-top: 175px;
    padding-bottom: 130px;
    @media screen and (max-width: 460px) {
        padding: 25px;
        line-height: none;
    }

    h1 {
        font-size: 30px;
        color: @color;
        letter-spacing: 1px;
    }

    .para-one {
        font-size: 17px;
        line-height: @line-height;
        color: @color;
        padding-top: 10px;
        margin: 0 auto;
        max-width: 500px;
        padding-left: @mobile-pading;
        padding-right: @mobile-pading;
    }
}




.experience {
    text-align: center;
    padding: 43px 0;
    border: solid 1px @bg-color;
    margin-bottom: 60px;
    @media screen and (max-width: 460px) {
        padding-bottom: 20px;
    }
}

.section-heading {
    font-size: 27px;
    font-weight: 600;
    letter-spacing: 3px;
    color: @color;
    text-transform: uppercase;
}

.timeline-wrapper{
 
    .timeline {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0 auto;
        padding-left: @mobile-pading;
        padding-right: @mobile-pading;
        max-width: 1200px;
        p {
            margin: 0;
        }
        &:last-child {
            .tl-two::before {
                bottom: 60px;
            }
        }

        @media (max-width: 575px) {
            display: flex;
            flex-direction: column;
            .h3,.h4 {
                display: block;
                text-align: left; 
            }

            .tl-one, .tl-two, .para-subhead {
                width: 100%;
            }
        }

    }

    .tl-one {
        text-align: right;
        width: 50%;
        opacity: 0;
        
        transform: translateX(-10%);
        transition: all 0.8s ease-in-out;
    
        &.active {
            opacity: 1;
            transform: translateX(0);
        }
    
        .h3 {
            font-size: 17px;
            font-weight: bold;
            color: @color;
            padding: 0 60px;
            margin: 0;
        }
    
        .h4 {
            font-size: 17px;
            color: @color;
            padding: 5px 60px;
            margin: 0;
        }
    }
    

    .tl-two {
        text-align: left;
        position: relative;
        width: 50%;
        padding-bottom: 65px;
        opacity: 0;
        
        transform: translateX(10%);
        transition: all 0.8s ease-in-out;
    
        &.active {
            opacity: 1;
            transform: translateX(0);
        }
    
        .h3 {
            font-size: 17px;
            color: @color;
            margin: 0;
            padding:0 60px;
        }
    
        &:before {
            content: "";
            position: absolute;
            display: block;
            width: 2px;
            top: 8px;
            left: 0px;
            bottom: -8px;
            background: @bg-color;
            @media (max-width: 575px) {
                visibility: hidden;
            }
        }
        &:after {
            content: "";
            position: absolute;
            display: block;
            width: 6px;
            height: 6px;
            top: 8px;
            left: 1px;
            background: @color;
            border-radius: 100%;
            transform: translate(-50%, -50%);
            @media (max-width: 575px) {
                visibility: hidden;
            }
        }
    }

}


.para-subhead {
    color: @color;
    padding: 5px 60px;
}

.clients-logos-list {
    
    list-style: none;
    text-align: center;
    position: relative;
    animation: animateleft 2.5s ease-in;
    opacity: 0;
    transform: scaleX(0);
    transform-origin: left center;
    transition: all 2s ease-in-out;
    &.active {
        opacity: 1;
        transform: scaleX(1);
    }
    li {
        display: inline-block;
        padding: 50px;
        @media screen and (max-width: 460px) {
            display: block;
            padding: 10px;
            margin-right: 25px;
        }
    }
}

.paragraphs {
    text-align: center;
    padding: 20px 20px 45px 20px;
    .paragraphs-text {
        
        display: inline-block;
        text-align: left;
        padding: 65px;
        line-height: @line-height;
        color: @color;
        padding-left: @mobile-pading;
        padding-right: @mobile-pading;
        margin: 0 auto;
        max-width: 350px;
        vertical-align: top;
        position: relative;
        opacity: 0;
        transform: scaleX(0);
        transform-origin: left center;
        &.b-1 {
            transition: all 1s ease-in-out;
        }

        &.b-2 {
            transition: all 1.5s ease-in-out;
        }

        &.b-3 {
            transition: all 2s ease-in-out;
        }

        &.active {
            opacity: 1;
            transform: scaleX(1);

        }
        @media screen and (max-width: 460px) {
            padding: 25px;
            line-height: none;
        }

        .h2-three {
            font-size: 17px;
            font-weight: bold;
            color: @color;
            letter-spacing: 2px;
            margin: 0;
            padding-bottom: 10px;
        }
    }
    
    p {
        margin: 0;
    }
}

.contact {
    text-align: center;
    padding: 43px 0;
    background-color: @bg-color;
}

.divider {
    height: 1px;
}

.skills {
    text-align: center;
    padding: 43px 0;
    color: #fff;
    background: @color;
}

.skills-name {
    font-size: 27px;
    font-weight: 600;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.form-info-flex {
    color: @color;
    display: flex;
    flex-direction: row;
    max-width: 2 * (470px + @mobile-pading);
    padding: 0 @mobile-pading;
    margin: 0 auto;
    @media screen and (max-width: 900px) {
        display: block;
    }
}


.form-style {
    color: @color;
    padding: 50px 55px 75px 0;
    opacity: 0;
    max-width: 500px;
    transform: translateY(20px);
    transition: all 2s ease-in-out;
    &.active {
        opacity: 1;
        transform: translateY(0);  
    }
    @media screen and (max-width: 900px) {
        padding: 30px 0 20px 0;
        max-width: 500px;
        margin: 0 auto;
    }


    .form-control {
        border: none;
        border-radius: 0;
        margin: 5px 0;
    }
}



.name-email {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 460px) {
        display: block;
        .name-input,
        .email-input {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.subject-message {
    display: flex;
    flex-direction: column;
}

.name-input {
    min-height: 35px;
    min-width: 205px;
    margin-right: 5px;
    flex: 1 1 100%;
    padding-bottom: 1px;
}

.email-input {
    min-height: 35px;
    min-width: 205px;
    margin-left: 5px;
    flex: 1 1 100%;
}

.subject-input {
    min-height: 35px;
}

textarea {
    min-height: 150px; 
}

.btn, .btn-primary {
    width: 100%;
    background-color: @color;
    border: none;
    color: #fff;
    font-size: 15px;
    height: 46px;
    margin-top: 5px;
    cursor: pointer;
    border-radius: 0;
    transition: background .3s;
    &:hover {
        background-color: lighten(@color, 10%);
    }
}

input {
    border: none;
}

.global-input {
    margin-bottom: 10px;
    padding: 10px 10px;
    border: none;
}

textarea {
    resize: vertical;
}

.info {
    padding: 50px 0 75px 55px;
    border-left: solid 1px #fff;
    opacity: 0;
    transform: translateY(20px);
    transition: all 2s ease-in-out;

    &.active {
        opacity: 1;
        transform: translateY(0);
    }
    p {
        margin: 0;
    }
    @media screen and (max-width: 900px) {
        padding: 30px 0 20px 0;
        max-width: 500px;
        margin: 0 auto;
        border-left: none;
        text-align: center;
        .info-two {
            width: 100%;
        }
    }
}

.EditMode,
.DesignMode{
    .info{
        opacity: 1;
        transform: translateY(0);
    }

    .tl-one {
        opacity: 1;
        transform: translateX(0);
    }

    .tl-two {
        opacity: 1;
        transform: translateX(0);
    }

    .profile-picture {
        opacity: 1;
        transform: translateX(0);
    }

    .text-info-custom {
        opacity: 1;
        transform: translateX(0);
    }

}

.info-two {
    border-top: solid 1px #b0b0b0;
    width: 288px;
    margin-top: 50px;
}


.info-one-wrapper {

    color: @color;

    .h2-two {
        font-size: 25px;
        color: @color;
        margin: 0;
    }

    .h6-two {
        color: @color;
        margin: 0;
        margin-bottom: 20px;
    }
}

.info-logo {
    list-style: none;
    padding: 0;
    li {
        display: inline-block;
        padding: 20px 6px;
    }
}

.copyrights {
    padding-top: 15px;
    padding-bottom: 15px;

    .footer-position {
        max-width: 940px;
        margin: 0 auto;
        text-align: left;
    }
}

.footer-text {
    font-size: 14px;
    color: #2F2E2E;
    .footer-link {
        color: #2F2E2E;
        text-decoration: underline;
    }
}

#p_lt_ctl11_On_lineForm_viewBiz {
    text-align: center;
}


// Large devices (desktops, less than 1200px)
@media (max-width: 1199px) {

        .copyrights .footer-position {
            text-align: center;
        }
}


// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {
    .clients-logos-list {
        display: block;
        padding: 0px;
        margin: 0px;
    }

}


// Small devices (landscape phones, less than 768px)
@media (max-width: 767px) {
    .clients-logos-list {
        display: inline-block;
        padding: 0px;
        margin: 0px;
    }
    .visit-card-info {
        flex-direction: column;
        .info-flex {
            width: 100%;
        }
    }
    .visit-card {
        margin: 0;
        padding: 90px 60px 40px 60px;
        top: 30px;
    }

   .profile-picture {
       height: 600px;
       background-size: cover;
       background-position: center;
   }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575px) {
    .clients-logos-list {
        display: block;
        padding: 0px;
        margin: 0px;
        padding-bottom: 50px;
    }
    .tl-one {
        width: 100%;
        text-align: left;
        padding: 0;
        margin: 0;
    }
    
    .para-subhead {
        padding: 10px auto;
        width: 310px;
    }
    .para-head {
        padding: 10px auto;
        width: 310px;
    }
    .visit-card-info .text-info {
        padding: 30px 10px 0 30px;
    }

    .profile-picture {
        height: 500px;
        background-size: cover;
        background-position: center; 
    }

}

@media (max-width: 385px) {
    .visit-card-logos {
        padding: 0;
        .card-logo-list {
            padding: 10px;
        }
    }
    .visit-card {
        padding: 60px 20px 0px 20px;
        top: -20px;
    }

    .profile-picture {
        height: 300px;
        background-size: cover;
        background-position: center;  
    }

}

@import "normalize";